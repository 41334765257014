import { Injectable } from '@angular/core'
import { StorageService } from '@core/services/storage.service'
import { LoggerService } from '@core/services/logger.service'
import { Enum } from '@core/interfaces/enum'

@Injectable({ providedIn: 'root' })
export class EnumService {

  private loaded: boolean = false
  private enums: Array<Enum> = []

  constructor(private storage: StorageService, private logger: LoggerService) {}

  public store(enums: Array<Enum>) {
    this.enums = enums
    this.storage.set('enums', window.btoa(JSON.stringify(enums)))
    this.loaded = true
  }

  public clear(): void {
    this.storage.remove('enums')

    this.enums = []
    this.loaded = false
  }

  private load(): void {
    this.enums = JSON.parse(window.atob(this.storage.get('enums') || 'W10=') || '[]') || []

    if (this.enums.length <= 0) {
      this.logger.error('EnumService', { message: 'Could not load Enums' })
    }

    this.loaded = true
  }

  public getLabel(name: string, value: string | number): any {
    if (!this.loaded) this.load()

    const foundEnum = this.enums.filter(item => item.name === name)

    if (foundEnum.length > 0) {
      const option = foundEnum[0].cases.filter(item => item.value == value)
      return option[0]?.label || '-'
    }

    return '-'
  }

  public getOptions(name: string): Enum | undefined {
    if (!this.loaded) this.load()

    const foundEnums = this.enums.find(obj => obj.name === name)

    return foundEnums ? foundEnums : undefined
  }
}
