import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '@env/environment'
import { TokenResponse } from '@core/interfaces/api/token-response'
import { StorageService } from '@core/services/storage.service'
import { map, Observable } from 'rxjs'
import { Router } from '@angular/router'
import { EnumService } from '@core/services/api/enum.service'
import { User } from '@core/models/shared/user.model'

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    private http: HttpClient,
    private storage: StorageService,
    private enumService: EnumService,
  ) {}

  public login(email: string, password: string): Observable<any> {
    return this.http.post<TokenResponse>(environment.backendUrl + 'token', { email, password })
      .pipe(map(response => {
        this.store(response)

        this.router.navigate([ '/' ])
      }))
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post(environment.backendUrl + 'users/send-reset-password-token', { email })
  }

  public resetPassword(token: string, password: string) {
    return this.http.post(environment.backendUrl + 'users/reset-password', { token, password })
  }

  public logout(): void {
    this.clear()
    this.enumService.clear()

    this.router.navigate([ '/auth/login' ])
  }

  public getToken(): string {
    return this.storage.get('token') || ''
  }

  public getRefreshToken(): string {
    return this.storage.get('refreshToken') || ''
  }

  public isLoggedIn(): boolean {
    return !this.isTokenExpired() && this.getToken().length > 0
  }

  public isTokenExpired(): boolean {
    const token = this.getToken()

    if (token === '') return true

    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp
    return (Math.floor((new Date).getTime() / 1000)) >= expiry
  }

  public refreshToken() {
    return this.http.post<TokenResponse>(environment.backendUrl + 'token/refresh', { refresh_token: this.storage.get('refreshToken') })
      .pipe(map(response => {
        this.store(response) // update values in storage
      }))
  }

  public hasRefreshToken(): boolean {
    return (this.storage.get('refreshToken') || null) !== null
  }

  private store(response: TokenResponse): void {
    this.storage.set('token', response.token)
    this.storage.set('refreshToken', response.refresh_token)
    this.storage.set('user', response.user)

    this.enumService.store(response.enums)
  }

  private clear(): void {
    this.storage.remove('token')
    this.storage.remove('refreshToken')
    this.storage.remove('user')
  }

  public hasPermission(id: number): boolean {
    if (id <= 0) return false

    const user = this.storage.get('user')

    if (!user) return false

    const foundPermission = user.permissions.find((p: any) => p.id === id)

    return !!(user && foundPermission)
  }

  public getUser(): User | null {
    return this.storage.get('user')
  }

  public isSuperUser(): boolean {
    const user = this.getUser()
    return user ? ['r.delker@tab.ruhr', 'tim@tab.ruhr'].includes(user.email) : false
  }
}
