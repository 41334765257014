import { Injectable, isDevMode } from '@angular/core'
import * as Sentry from "@sentry/angular"

export type LoggerData = {
  message: string
  additionalInfos?: any
}

@Injectable({ providedIn: 'root' })
export class LoggerService {

  public error(serviceName: string, data: LoggerData): void {

    if (isDevMode()) {
      console.error(`Error in ${serviceName}`, data)
    } else {
      Sentry.withScope((scope) => {
        if(data.additionalInfos) {
          scope.setExtra('data', data.additionalInfos)

          if(data.additionalInfos?.id) scope.setExtra('id', data.additionalInfos.id)
        }
        Sentry.captureException(new Error(`${serviceName}: ${data.message}`));
      });
    }
  }

}
