import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class StorageService {
  public set(key: string, value: any): void {
    if (typeof value !== 'string') {
      value = JSON.stringify(value)
    }

    localStorage.setItem(key, value)
  }

  public get(key: string): any {
    const value: any = localStorage.getItem(key)
    const result = this.isJson(value)

    return result !== false ? result : value
  }

  public getBoolean(key: string): boolean {
    const value: any = localStorage.getItem(key)

    return value == 'true'
  }

  public remove(key: string): void {
    localStorage.removeItem(key)
  }

  private isJson(value: string): string | boolean {
    try {
      return JSON.parse(value)
    } catch (e) {
      return false
    }
  }
}
